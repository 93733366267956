import React from 'react';
import styles from '@styles/components/ItemRealEstate/RealEstate.module.css';
import TopRealEstate from '@components/ItemRealEstate/components/TopRealEstate';
import BottomRealEstate from '@components/ItemRealEstate/components/BottomRealEstate';
import { estate } from '@utils/configs/RealEstateConfiguarations/realEstate';
type Props = {
  wrapperStyle?: string,
  item?: Object,
  onPress?: Function,
  postedId?: String,
};

export default function RealEstate(props: Props) {
  const { item = {}, wrapperStyle, postedId } = props;

  const isEstate = item?.demandEstate === estate,
    href = isEstate
      ? `/chi-tiet-tin-rao/${item.id}`
      : `/chi-tiet-can-mua-can-thue/${item.id}`;

  return (
    <div
      className={
        postedId
          ? `w-full border border-gray-borderPosted rounded-2xl ${styles.realEstate} ${wrapperStyle}`
          : `${styles.realEstate} ${wrapperStyle}`
      }>
      <TopRealEstate item={item} />
      <a className="flex-1" href={href}>
        <BottomRealEstate item={item} />
      </a>
    </div>
  );
}
