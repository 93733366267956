import styles from '@styles/components/ItemRealEstate/RealEstate.module.css';
import { getTagRealEstate } from '@utils/helper';
import React from 'react';

import dynamic from 'next/dynamic';
import ButtonSave from '@components/ItemRealEstate/ButtonSave';
import { getImage } from '@components/ItemRealEstate/utils';
const CodeIcon = dynamic(() => import('@public/svg/code.svg'), { ssr: false });
const Image360Icon = dynamic(() => import('@public/svg/ico-360.svg'), {
  ssr: false,
});
const CameraIcon = dynamic(() => import('@public/svg/ico-camera.svg'), {
  ssr: false,
});
const PictureIcon = dynamic(() => import('@public/svg/ico-picture.svg'), {
  ssr: false,
});
const OwnerIcon = dynamic(() => import('@public/svg/ownerRealEstateLogo.svg'), {
  ssr: false,
});
const Vip1Icon = dynamic(() => import('@public/svg/vip1Logo.svg'), {
  ssr: false,
});
const Vip2Icon = dynamic(() => import('@public/svg/vip2Logo.svg'), {
  ssr: false,
});
type Props = {
  item?: Object,
};

type MediaCountItemProps = {
  mediaCount?: string,
  icon?: any,
};
function MediaCountItem({ mediaCount, icon }: MediaCountItemProps) {
  const Icon = icon;
  if (mediaCount === 0) {
    return <></>;
  }
  return (
    <div className={`${styles.countMediaFile}`}>
      <div className={`${styles.textCountMedia} mr-1 truncate`}>
        {mediaCount}
      </div>
      <Icon className={styles.iconCountMediaFile} />
    </div>
  );
}

export default function TopRealEstate({ item }: Props) {
  const { mediaUrls, realEstateId } = item;
  const video = mediaUrls?.filter((e) =>
      ['video', 'youtube'].includes(e?.type),
    ),
    image360 = mediaUrls?.filter((e) => e?.type === '360image'),
    image = mediaUrls?.filter((e) => e?.type === 'image');
  const Logo = item?.isAuthorizedRe
    ? OwnerIcon
    : item?.postType === 1
    ? Vip1Icon
    : item?.postType === 2
    ? Vip2Icon
    : null;

  return (
    <div className={styles.imageRealEstate}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url('${getImage(mediaUrls)}')`,
        }}
      />
      {Logo && <Logo className={styles.logoRealEstate} />}

      <img
        src={getTagRealEstate({ ...item })}
        alt="tag"
        className={styles.tagRealEstate}
      />
      <div className={styles.mediaCountContainer}>
        <MediaCountItem icon={Image360Icon} mediaCount={image360?.length} />
        <MediaCountItem icon={CameraIcon} mediaCount={video?.length} />
        <MediaCountItem icon={PictureIcon} mediaCount={image?.length} />
      </div>
      <div className={styles.realEstateId} id="acb">
        <CodeIcon className={styles.codeIcon} />
        <div className={styles.txtRealEstateId}>{realEstateId}</div>
      </div>
      <ButtonSave item={item} />
    </div>
  );
}
