import styles from '@styles/components/ItemRealEstate/BottomRealEstate.module.css';
import ItemDescription from '@components/ItemRealEstate/itemDescription';
import React from 'react';
import { useDescriptionRealEstate } from '@components/ItemRealEstate/utils';
import { usePrice } from '@components/ItemRealEstate/hooks';
// import realEstateCommonConfigs from '@utils/configs/RealEstateConfiguarations/RealEstateCommonConfigs.json';
import RenderTime from '@components/ItemRealEstate/components/RenderTime';
import colorPostTitleRealEstate from '@utils/helper';
type BottomRealEstateProps = {
  item?: Object,
};
export default function BottomRealEstate({ item }: BottomRealEstateProps) {
  const { postTitle } = item;
  const description = useDescriptionRealEstate({ item });
  const { pricePost, unitPrice } = usePrice(item);

  // const { priceUnitsForRent } = realEstateCommonConfigs;
  // const priceUnitName =
  //   priceUnitsForRent.find((e) => e.key === item?.priceUnit)?.value || '';

  return (
    <div className={styles.description}>
      <div className="flex-1 flex flex-col">
        <div
          className={styles.postTitleRealEstate}
          style={{ color: colorPostTitleRealEstate(item) }}>
          {postTitle?.toString()?.toLowerCase()}
        </div>
        <div className={styles.descriptionInner}>
          {description.map((des, index) => {
            return <ItemDescription {...des} key={index} />;
          })}
        </div>
      </div>
      <div className={styles.priceContainer}>
        <p className={styles.unitPrice}>
          <b className={styles.priceText}>{pricePost} </b>
          {unitPrice}{' '}
          {item?.isForSell || item?.isNeedToBuy
            ? ' VNĐ'
            : !item?.isForSell || item?.isNeedToRent
            ? ' VNĐ/ Tháng'
            : 'ihhi'}
          {/* {priceUnitName} */}
        </p>
        <RenderTime createdAt={item?.createdAt} />
      </div>
      <div className="flex-1 flex flex-row absolute right-4 space-x-1 bottom-32px">
        {item?.postStatus === 'expired' && (
          <img
            alt="expired"
            src="/svg/realEstateExpired.svg"
            className="lg:w-30px lg:h-30px w-16px h-16px"
          />
        )}
        {item?.privateNote?.length > 0 && (
          <img
            alt="note"
            src="/svg/note.svg"
            className="lg:w-30px lg:h-30px w-16px h-16px"
          />
        )}
      </div>
    </div>
  );
}
