import RealEstate from '@components/ItemRealEstate/realEstate';
import CarouselV2 from '@components/CarouselV2';
import styles from '@styles/components/Home/Home.module.scss';

type Props = {
  data?: Array,
};
export default function RealEstateCarousel(props: Props) {
  const { data = [] } = props;
  return (
    <div className={`w-full ${styles.realEstateCarousel}`}>
      <CarouselV2
        breakpoints={{
          320: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 9,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 30,
          },
        }}
        showNavigation
        data={data}
        RenderItem={({ item, index }) => (
          <RealEstate
            wrapperStyle="flex flex-col flex-1"
            item={item}
            index={index}
          />
        )}
      />
    </div>
  );
}
